<template>
	<div
		class="order-content"
		:class="{ noUpgrade: !data.upgradeSeriesId }"
		v-if="data[optionDetailListKey]"
	>
		<div
			v-for="(list, index) in data[optionDetailListKey]"
			:key="list.optionDetailName + index"
		>
			<span>+ {{ list.optionDetailName }}</span>
			<span>
				{{
					list[optionPriceKey] > 0
						? `($${$stepPrice(list[optionPriceKey] * list.count)})`
						: ''
				}}
			</span>
		</div>
		<div class="order-content-remark" v-if="data.remark">{{ data.remark }}</div>
	</div>
</template>
<script>
export default {
	name: 'OptionDetailList',
	props: {
		data: {
			type: Object,
			required: true,
		},
		optionDetailListKey: {
			type: String,
			required: false,
			default: 'optionList',
		},
		optionPriceKey: {
			type: String,
			required: false,
			default: 'price',
		},
	},
	data() {
		return {}
	},
	// computed: {},
	// methods: {},
	// created() {},
	// mounted() {},
	// beforeDestroy () {},
}
</script>

<style lang="sass" scoped>
.order-content
  flex-basis: 100%
  color: #8E8E8E
  font-size: 14px
  line-height: 20px
  margin: 10.5px 0 0px 0
  &-remark
    font-size: 13px
    line-height: 18px
    border: 1px solid #BEBEBE
    padding: 2.5px 15.5px 2.5px 11px
    color: #8E8E8E
    border-radius: 2.5px
    margin-top: 8px
    display: block
    width: fit-content
  &.noUpgrade
    margin-top: 5px
</style>
